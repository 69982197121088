import React from 'react';
import BannerImg from './BannerImg';

const Banner = () => {
    return (
        <div className='bg-[#f4f3f3]'>
            <div className='flex mx-4 h-96 justify-between'>
                <div className='w-[55%] flex flex-col'>
                    <div className='h-1/3 flex items-center'>
                        <span className='font-bold text-2xl'>Unlock your True Potential with <a href="www.google.com" className='cursor-pointer underline'>saqtechnology.com</a></span>
                    </div>
                    <div className='h-1/3 flex items-center'>
                        <p className='text-lg leading-9'>Excel In Data Structures, Algorithms, Programming, And Cutting-Edge Tech. Boost Your Career With Our Courses And Certificates.</p>
                    </div>
                    <div className='h-1/3 flex items-center flex-wrap justify-between w-3/5'>
                        <div>
                            <button className="bg-[#0e76a8] hover:bg-[#fcb900] hover:text-black text-white w-48 h-14 py-2 px-6 font-medium rounded-full transition duration-500 ease-in-out">
                                Explore Courses
                            </button>
                        </div>
                        <div>
                            <button className="bg-[#0e76a8] hover:bg-[#fcb900] hover:text-black text-white w-48 h-14 py-2 px-6 font-medium rounded-full transition duration-500 ease-in-out">
                                Explore Problem
                            </button>
                        </div>
                    </div>
                </div>
                <div className='w-2/5 flex justify-center items-center'>
                    <div className='h-full w-full'>
                        <BannerImg />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner;