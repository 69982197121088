import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Course from '../../Body/Course';
import { setCoursesData, setLoader } from './../../../Slices/AdminSlice';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const FreeCourses = () => {
  let courses = useSelector(store => store.admin.coursesData);
  let loading = useSelector(store => store.admin.loading);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLoader());
    fetch("https://makfreelancer.com/myproject/hackerAlgo/api/Mobile_Services/courseList")
      .then(res => res.json())
      .then(data => dispatch(setCoursesData(data)))
  }, [])

  if (loading)
    return <Box className=" fixed z-50 mx-96 my-36">
      <CircularProgress />
    </Box>

  return (
    <div className='my-16 mx-4'>
      <div className='flex flex-wrap my-4'>
        {
          courses && courses.length > 0 &&
          courses.map(course => {
            return course.courseCategory === "0" && <Course course={course} key={course.courseId} />
          })
        }
      </div>
    </div>
  )
}

export default FreeCourses