export const tableColumns = (page) => {
    let headerColumns = [];
    switch (page) {
        case "Events": headerColumns =
            [
                { field: 'slNo', headerName: 'SL.No', width: 120, editable: false },
                { field: 'eventTitle', headerName: 'Event Title', width: 150, editable: false },
                { field: 'eventDesc', headerName: 'Event Description', width: 150, editable: false },
                { field: 'eventCategory', headerName: 'Event Category', width: 150, editable: false },
                { field: 'eventStatus', headerName: 'Event Status', width: 150, editable: false },
            ]
            break;
        case "Courses": headerColumns =
            [
                { field: 'slNo', headerName: 'SL.No', width: 120, editable: false },
                { field: 'courseTitle', headerName: 'Course Title', width: 150, editable: false },
                { field: 'courseDesc', headerName: 'Course Description', width: 150, editable: false },
                { field: 'courseCategory', headerName: 'Course Category', width: 150, editable: false },
                { field: 'courseStatus', headerName: 'Course Status', width: 150, editable: false },
            ]
            break;
        default: break;
    }
    return headerColumns;
}