import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {

  const [hover, setHover] = useState({ explore: false, practice: false, resource: false })

  const handleMouseEnter = (tab) => {
    if (tab === "explore")
      setHover({ explore: true, practice: false, resource: false });
    else if (tab === "practice")
      setHover({ explore: false, practice: true, resource: false });
    else
      setHover({ explore: false, practice: false, resource: true });
  }

  const handleMouseLeave = (e) => {
    console.log(e)
    setHover({ explore: false, practice: false, resource: false });
  }

  return (
    <div className='flex m-2 justify-between'>
      <div className='w-1/5'>
        <a href='https://saqtechnology.com/'>
          <span>
            {/* <img className="h-16" src="https://hackeralgo.com/wp-content/uploads/2023/11/logo-website.png" /> */}
            SAQ
          </span>
        </a>
      </div>
      <div className='flex w-3/4'>
        <div className='w-2/3'>
          <ul className='h-full flex justify-around items-center'>
            <li className='h-full flex items-center cursor-pointer relative font-semibold'><Link to="/">Home</Link></li>
            <li className='h-full flex items-center relative group cursor-pointer font-semibold'>
              <a>Explore</a>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-1 pt-0.5 w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg>
              <div className="absolute hidden group-hover:block top-12 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                <div className="py-1" role="none">
                  <Link to="/view-premium-courses"><a href="#" className="text-gray-700 hover:underline leading-7 hover:text-[#9b51e0] block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-0">View Premium Courses</a></Link>
                  <Link to="/view-free-courses"><a href="#" className="text-gray-700 hover:underline leading-7 hover:text-[#9b51e0]  block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-1">View Free Courses</a></Link>
                  <Link to="/coding-problems"><a href="#" className="text-gray-700 hover:underline leading-7 hover:text-[#9b51e0]  block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-2">Coding Problem</a></Link>
                  <Link to="/roadmap"><a href="#" className="text-gray-700 hover:underline leading-7 hover:text-[#9b51e0]  block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-3">Roadmap</a></Link>
                  <Link to="/cheat-sheet"><a href="#" className="text-gray-700 hover:underline leading-7 hover:text-[#9b51e0]  block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-4">Cheat Sheet</a></Link>
                </div>
              </div>
            </li>
            <li className='h-full flex items-center cursor-pointer relative font-semibold'>Tutorial</li>
            <li className='h-full flex items-center group relative cursor-pointer font-semibold'>
              <a>Practice</a>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-1 pt-0.5 w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg>
              <div className="absolute hidden group-hover:block top-12 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                <div className="py-1" role="none">
                  <a href="#" className="text-gray-700 hover:underline leading-7 hover:text-[#9b51e0] block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-0">Coding</a>
                  <a href="#" className="text-gray-700 hover:underline leading-7 hover:text-[#9b51e0] block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-1">Theory Q&A</a>
                  <a href="#" className="text-gray-700 hover:underline leading-7 hover:text-[#9b51e0] block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-2">Certification Exam</a>
                  <a href="#" className="text-gray-700 hover:underline leading-7 hover:text-[#9b51e0] block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-3">Explore All</a>
                </div>
              </div>
            </li>
            {/* <li className='h-full flex items-center cursor-pointer relative font-semibold'>Pricing</li> */}
            {/* <li className='h-full flex items-center group relative cursor-pointer font-semibold'>
              <a>Resource</a>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-1 pt-0.5 w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg>
              <div className="absolute hidden group-hover:block top-12 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                <div className="py-1" role="none">
                  <a href="#" className="text-gray-700 hover:underline leading-7 hover:text-[#9b51e0] block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-0">Interview Experience</a>
                  <a href="#" className="text-gray-700 hover:underline leading-7 hover:text-[#9b51e0] block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-1">Company Insight</a>
                  <a href="#" className="text-gray-700 hover:underline leading-7 hover:text-[#9b51e0] block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-2">Live Event</a>
                  <a href="#" className="text-gray-700 hover:underline leading-7 hover:text-[#9b51e0] block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-3">Success Stories</a>
                  <a href="#" className="text-gray-700 hover:underline leading-7 hover:text-[#9b51e0] block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-4">Blog</a>
                </div>
              </div>
            </li> */}
            <li className='h-full flex items-center cursor-pointer relative font-semibold'>Contact</li>
          </ul>
        </div>
        <div className='w-1/12 flex items-center justify-center'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
            <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
          </svg>
        </div>
        <div className='w-1/12 flex items-center justify-center'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
          </svg>
        </div>
        <div className='w-1/6 flex items-center justify-center'>
          <button className="bg-[#0e76a8] hover:bg-[#fcb900] hover:text-black text-white py-2 px-6 font-medium rounded-full transition duration-500 ease-in-out">
            Join For
          </button>
        </div>
      </div>
    </div>
  )
}

export default Navbar