import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import { setEventsData, setLoader, courseDataAddUpdateSuccess } from '../../Slices/AdminSlice';
import { DataGrid } from '@mui/x-data-grid';
import { tableColumns } from '../../utils/columns';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem } from '@mui/x-data-grid';

const Events = () => {
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [inputs, setInputs] = useState({ slNo: null, eventId: null, eventTitle: "", eventDesc: "", eventCategory: "", eventStatus: "" })
    let events = useSelector(store => store.admin.eventsData);
    let loading = useSelector(store => store.admin.loading);
    let updateMessage = useSelector(store => store.admin.updateMessage);
    const dispatch = useDispatch();
    const columns = tableColumns("Events");

    useEffect(() => {
        dispatch(setLoader());
        fetch("https://makfreelancer.com/myproject/hackerAlgo/api/Mobile_Services/eventList")
            .then(res => res.json())
            .then(data => dispatch(setEventsData(data)))
    }, [])

    const handleInputFieldChange = (obj, value) => {
        setInputs((prevValue) => {
            return { ...prevValue, [obj]: value }
        })
    }

    const handleAdd = () => {
        dispatch(setLoader());
        setEditOpen(false);
        setOpen(false);
        fetch("https://makfreelancer.com/myproject/hackerAlgo/api/Mobile_Services/add_event", {
            method: "POST",
            body: JSON.stringify(inputs),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === 1)
                    dispatch(courseDataAddUpdateSuccess())
            })
            .catch(err => console.log("Error = ", err))
    }

    if (updateMessage) {
        dispatch(setLoader());
        fetch("https://makfreelancer.com/myproject/hackerAlgo/api/Mobile_Services/eventList")
            .then(res => res.json())
            .then(data => dispatch(setEventsData(data)))
    }

    const handleEdit = (id) => {
        let event = events.filter(ele => ele.eventId === id);
        setInputs(event[0]);
        setEditOpen(true);
    }

    const handleRemoval = (id) => {
        let event = events.filter(ele => ele.eventId === id);
        dispatch(setLoader());
        fetch("https://makfreelancer.com/myproject/hackerAlgo/api/Mobile_Services/delete_course", {
            method: "POST",
            body: JSON.stringify({ eventId: event[0].eventId }),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === 1)
                    dispatch(courseDataAddUpdateSuccess())
            })
            .catch(err => console.log("Error = ", err))
    }

    let column = {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => {
            return [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    className="textPrimary"
                    onClick={() => { handleEdit(id) }}
                    color="inherit"
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => { handleRemoval(id) }}
                    color="inherit"
                />,
            ];
        },
    }
    columns.push(column);

    return (
        <div>
            <div className='my-8'>
                <p className='text-xl font-semibold'>Add/Edit/Delete Events</p>
            </div>
            {
                loading === true || events === null ?
                    <Box className=" fixed z-50 mx-96 my-36">
                        <CircularProgress />
                    </Box>
                    :
                    <div>
                        <div className='my-4'>
                            <Button onClick={() => { setInputs({ slNo: null, courseId: null, eventTitle: "", eventDesc: "", eventCategory: "", eventStatus: "" }); setOpen(true) }} variant="contained">Add Event</Button>
                        </div>
                        <Box sx={{ height: 400, width: '100%' }}>
                            <DataGrid
                                rows={events}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                checkboxSelection
                                disableSelectionOnClick
                                getRowId={(row) => row?.eventId}
                                experimentalFeatures={{ newEditingApi: true }}
                            />
                        </Box>
                    </div>
            }
            {
                (open || editOpen) &&
                <Dialog
                    open={open || editOpen}
                    maxWidth='md'
                    onClose={() => { open ? setOpen(false) : editOpen && setEditOpen(false) }}
                >
                    <DialogTitle>{open ? "Add an event" : editOpen && "Update Event"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {open ? "Please enter the following details to add a new event" : editOpen && "Update Fields"}
                        </DialogContentText>
                        <div className='flex flex-wrap justify-between'>
                            <div className='m-8'>
                                <TextField onChange={(e) => { handleInputFieldChange("eventTitle", e.target.value) }} autoFocus value={inputs.eventTitle} required margin="dense" id="title" name="title" label="Course Title" type="text" variant="standard" />
                            </div>
                            <div className='m-8'>
                                <TextField onChange={(e) => { handleInputFieldChange("eventDesc", e.target.value) }} autoFocus value={inputs.eventDesc} required margin="dense" id="desc" name="desc" label="Course Description" type="text" variant="standard" />
                            </div>
                            <div className='m-8'>
                                <TextField onChange={(e) => { handleInputFieldChange("eventCategory", e.target.value) }} autoFocus value={inputs.eventCategory} required margin="dense" id="category" name="category" label="Course Category" type="text" variant="standard" />
                            </div>
                            <div className='m-8'>
                                <TextField onChange={(e) => { handleInputFieldChange("eventStatus", e.target.value) }} autoFocus value={inputs.eventStatus} required margin="dense" id="status" name="status" label="Course Status" type="text" variant="standard" />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { open ? setOpen(false) : editOpen && setEditOpen(false) }}>Cancel</Button>
                        <Button type="submit" onClick={handleAdd}>{open ? "Add" : editOpen && "Update"}</Button>
                    </DialogActions>
                </Dialog>
            }
        </div >
    )
}

export default Events;