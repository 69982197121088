import React, { useEffect, useState } from 'react';
import Event from './Event';
import { useSelector, useDispatch } from 'react-redux';
import { setEventsData, setLoader } from '../../Slices/AdminSlice';
import { Button } from '@mui/material';

const Events = () => {

    let events = useSelector(store => store.admin.eventsData);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLoader());
        fetch("https://makfreelancer.com/myproject/hackerAlgo/api/Mobile_Services/eventList")
            .then(res => res.json())
            .then(data => dispatch(setEventsData(data)))
    }, [])

    return (
        <div className='bg-[#EEF2F6] my-16 py-12'>
            <div className='mx-4'>
                <div className='flex justify-between'>
                    <div className='flex flex-col'>
                        <span className='sm:text-3xl text-base mb-2 font-bold'>Upcoming Events</span>
                    </div>
                    <div>
                        <button className="w-40 text-[#6440FB] font-semibold rounded-lg border-2 border-solid border-[#6440FB] py-3 hover:text-white hover:bg-[#6440FB]">View All Events</button>
                    </div>
                </div>
                <div className='flex flex-wrap my-4'>
                    {
                        events && events.length > 0 &&
                        events.map(event => {
                            return <Event event={event} key={event.eventId} />
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Events