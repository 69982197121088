import React from 'react'

const Event = ({ event }) => {

    return (
        <div className='w-80 h-96 my-4 mx-10 shadow-xl rounded-xl'>
            <div className='h-3/5'>
                <img className='h-full' src="https://img.freepik.com/free-psd/technology-template-design_23-2150317282.jpg?w=996&t=st=1716567781~exp=1716568381~hmac=656764a44552106b749107677467533ead3a7703727224f676dc5a175a916393"></img>
            </div>
            <div className='mx-3 h-2/5 flex flex-col justify-around py-3'>
                <div className='font-semibold text-lg'>Event Name - {event.eventTitle}</div>
                <div className='font-semibold text-sm'>Event Description - {event.eventDesc}</div>
                <div className='flex justify-between font-semibold text-sm'>
                    <div className={(event.eventStatus === "1" ? 'h-6 w-fit p-0.25 px-2 text-center rounded-lg bg-green-100 text-green-900' : "h-6 w-fit p-0.25 px-2 text-center rounded-lg bg-red-100 text-red-900")}>{event.eventStatus === "1" ? "Active" : "Inactive"}</div>
                    <div className={(event.eventCategory === "1" ? 'h-6 w-fit p-0.25 px-2 text-center rounded-lg bg-green-100 text-green-900' : "h-6 w-fit p-0.25 px-2 text-center rounded-lg bg-red-100 text-red-900")}>{event.eventCategory === "1" ? "Premium" : "Free"}</div>
                </div>
            </div>
        </div>
    )
}

export default Event