import React from 'react';
import TopHeader from './TopHeader';
import Navbar from './Navbar';

const Header = () => {
  return (
    <div>
        <div><TopHeader/></div>
        <div><Navbar/></div>
    </div>
  )
}

export default Header