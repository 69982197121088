import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCoursesData, setLoader } from '../../Slices/AdminSlice';
import Course from './Course';

const Courses = () => {

  const [active, setActive] = useState({ first: true, second: false, third: false });
  let courses = useSelector(store => store.admin.coursesData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoader());
    fetch("https://makfreelancer.com/myproject/hackerAlgo/api/Mobile_Services/courseList")
      .then(res => res.json())
      .then(data => dispatch(setCoursesData(data)))
  }, [])

  const handleClick = (tab) => {
    if (tab === "courses")
      setActive({ first: true, second: false, third: false });
    else if (tab === "design")
      setActive({ first: false, second: true, third: false });
    else
      setActive({ first: false, second: false, third: true });
  }

  return (
    <div className='my-16 mx-4'>
      <div className='flex justify-between'>
        <div className='flex flex-col'>
          <span className='sm:text-3xl text-base mb-2 font-bold'>Our Most Popular Courses</span>
          <span className='text-sm text-[#7A7A7A]'>10,000+ unique online course list designs</span>
        </div>
        <div className='flex flex-wrap justify-around w-fit h-1/2'>
          <div onClick={() => { handleClick("courses") }} className={active.first ? 'bg-[rgba(14,118,168,0.07)] cursor-pointer rounded-full px-4 py-2 flex justify-center items-center' : 'hover:bg-[rgba(14,118,168,0.07)] cursor-pointer rounded-full px-4 py-2 flex justify-center items-center'}><span className={active.first ? 'text-[#0e76a8] font-semibold' : 'hover:text-[#0e76a8] text-xs sm:text-base font-semibold text-[#4f547b]'}>All Courses</span></div>
          <div onClick={() => { handleClick("design") }} className={active.second ? 'bg-[rgba(14,118,168,0.07)] cursor-pointer rounded-full px-4 py-2 flex justify-center items-center' : 'hover:bg-[rgba(14,118,168,0.07)] cursor-pointer rounded-full px-4 py-2 flex justify-center items-center'}><span className={active.second ? 'text-[#0e76a8] font-semibold' : 'hover:text-[#0e76a8] text-xs sm:text-base font-semibold text-[#4f547b]'}>Graphic Design</span></div>
          <div onClick={() => { handleClick("dev") }} className={active.third ? 'bg-[rgba(14,118,168,0.07)] cursor-pointer rounded-full px-4 py-2 flex justify-center items-center' : 'hover:bg-[rgba(14,118,168,0.07)] cursor-pointer rounded-full px-4 py-2 flex justify-center items-center'}><span className={active.third ? 'text-[#0e76a8] font-semibold' : 'hover:text-[#0e76a8] text-xs sm:text-base font-semibold text-[#4f547b]'}>Development</span></div>
        </div>
      </div>
      <div className='flex flex-wrap my-4'>
        {
          courses && courses.length > 0 &&
          courses.map(course=>{
            return <Course course={course} key={course.courseId}/>
          })
        }
      </div>
    </div>
  )
}

export default Courses