import digitalMarketing from "../utils/Assets/digitalMarketing.png";
import webDevelopment from "../utils/Assets/webDevelopment.png";
import graphicDesign from "../utils/Assets/graphicDesign.png";
import socialSciences from "../utils/Assets/socialSciences.png";
import photography from "../utils/Assets/photography.png";
import artHumanities from "../utils/Assets/artHumanities.png";
import promotion from "../utils/Assets/promotion.png";
import itSoftware from "../utils/Assets/itSoftware.png";


export const categoriesData = [
    {name:"SalesForce",img:promotion,desc:"2 Courses"},    
    {name:"Full Stack Development",img:webDevelopment,desc:"6 Courses"},
    {name:"Azure",img:digitalMarketing,desc:"2 Courses"},
    {name:"SAP",img:graphicDesign,desc:"5 Courses"},
    {name:"UI Path",img:socialSciences,desc:"1 Courses"},
    // {name:"",img:photography,desc:"4 Courses"},
    // {name:"",img:artHumanities,desc:"2 Courses"},
   
    {name:"IT and Software",img:itSoftware,desc:"5 Courses"},
];

export const leftPanelData = [
    {
        parent:"Courses",
        children:
        [
            // {
            //     parent:"Add Course",
            //     children:[],
            //     target:"add-course"
            // },
            // {
            //     parent:"Remove Course",
            //     children:[],
            //     target:"remove-course"
            // }
        ],
        target:"courses"
    },
    {
        parent:"Events",
        children:
        [
            // {
            //     parent:"Add Event",
            //     children:[],
            //     target:"add-event"
            // },
            // {
            //     parent:"Remove Event",
            //     children:[],
            //     target:"remove-event"
            // }
        ],
        target:"events"
    }
]

export const navbarData = [
    {
        parent:"Home",
        children:[]
    },
    {
        parent:"Explore",
        children:[
            "View Premium Courses",            
            "Coding Practise",
           
        ]
    },
    {
        parent:"Tutorial",
        children:[]
    },
    {
        parent:"Practice",
        children:[
            "Coding",
            "Theory Q&A",
            "Certification Exam",
            "Explore All"
        ]
    },
    {
        parent:"Pricing",
        children:[]
    },
    // {
    //     parent:"Resource",
    //     children:[
    //         "Interview Experience",
    //         "Company Insight",
    //         "Live Event",
    //         "Success Stories",
    //         "Blog"
    //     ]
    // },
    {
        parent:"Contact",
        children:[]
    },
]

export const courses = [
    {courseId:1,courseTitle:"Course 1",courseDesc:"Course 1 Description",courseCategory:"Computer Science",courseStatus:"Active"}
]

export const events = [
    {eventId:1,eventTitle:"Tech Gather",eventDesc:"Event 1 Description",eventCategory:"Tech Fest",eventStatus:"Active"}
]
