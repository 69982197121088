import React from 'react';
import LeftPanel from './LeftPanel';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedPanel } from '../../Slices/AdminSlice';
import Courses from './Courses';
import Events from './Events';

const Dashboard = () => {

  const selectedPanel = useSelector(store => store.admin.selectedPanel);
  const dispatch = useDispatch();
  function setTabPanel(tab) {
    dispatch(setSelectedPanel(tab))
  }

  return (
    <div className="flex flex-row overflow-hidden h-screen">
      <LeftPanel setTabPanel={setTabPanel} />
      {
        selectedPanel === "Events"
          ?
          <Events/>
          :
          <Courses/>
      }
    </div>
  )
}

export default Dashboard;