import React from 'react';
import a from './a.svg';

const BannerImg = () => {    
     const videoSrc = "https://www.youtube.com/embed/_LgPYdTdwGQ?autoplay=1&mute=1&loop=1&playlist=_LgPYdTdwGQ";
    return (
        <div className='h-full w-full'>
            {/* <img className='object-cover h-full w-full' src={a} alt="Your SVG" /> */}
            <iframe
                width="500"
                height="360"
                src={videoSrc}
                title="SAQ Technologies"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
            ></iframe>
        </div>
    )
}

export default BannerImg