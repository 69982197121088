import { createSlice } from '@reduxjs/toolkit';
import { navbarData } from '../utils/constants';

const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        selectedPanel: "Courses",
        navData: navbarData,
        coursesData: null,
        eventsData: null,
        loading: false
    },
    reducers: {
        setSelectedPanel: (state, action) => {
            let setSelectedPanel = { ...state };
            setSelectedPanel.selectedPanel = action.payload;
            return setSelectedPanel;
        },
        setCoursesData: (state, action) => {
            let coursesDataState = { ...state };
            let dbCourses = action.payload.course_details;
            
            dbCourses = dbCourses.map((ele, idx) => { ele.slNo = idx + 1; return ele });
            coursesDataState.coursesData = dbCourses;
            coursesDataState.loading = false;
            return coursesDataState;
        },
        setEventsData: (state, action) => {
            let eventsDataState = { ...state };
            let dbEvents = action.payload.event_details;
            dbEvents = dbEvents.map((ele,idx)=>{ ele.slNo = idx+1; return ele});
            eventsDataState.eventsData = dbEvents;
            eventsDataState.loading = false;
            return eventsDataState;
        },
        setLoader: (state) => {
            let loader = { ...state };
            loader.loading = loader.loading ? false : true;
            loader.updateMessage = false;
            return loader;
        },
        courseDataAddUpdateSuccess:(state)=>{
            let addUpdateCourses = {...state};
            addUpdateCourses.loading = false;
            addUpdateCourses.updateMessage = true;
            return addUpdateCourses;
        },
        addCourses: (state, action) => {
            let initialCourses = { ...state };
            if (initialCourses.coursesData === null)
                initialCourses.coursesData = [action.payload];
            else {
                let courseArray = [...initialCourses.coursesData];
                let course = action.payload;
                course.courseId = (courseArray.length + 1).toString();
                courseArray.push(action.payload);
                courseArray = courseArray.map((ele, idx) => { ele.slNo = idx + 1; return ele });
                initialCourses.coursesData = courseArray;
                return initialCourses;
            }
        }
    }
});

export const { setSelectedPanel, setCoursesData, setEventsData, setLoader, courseDataAddUpdateSuccess } = adminSlice.actions;
export default adminSlice.reducer;