import React, { useState } from 'react';
import { setSelectedPanel } from '../../Slices/AdminSlice';
import { useDispatch } from 'react-redux';

const LeftPanelItem = ({ setTabPanel,item }) => {

    const [expand, setExpand] = useState(false);
    const dispatch = useDispatch();

    const handleClick = (tab,child) => {
        setExpand(!expand);
        if(child === 0)
            setTabPanel(tab)
    }


    return (
        <div className='cursor-pointer'>
            <div className='text-white text-lg leading-[3] rounded-l-xl pl-3 hover:bg-[#323a4b]' onClick={()=>{handleClick(item.parent,item?.children?.length)}}>
                <div className='w-4/5 flex justify-between'>
                    <div>{item.parent}</div>
                    {item?.children?.length > 0 &&
                        <div className='flex justify-center items-center'>
                            <svg transform={expand?'rotate(180)':''} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-1 pt-0.5 w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                            </svg>
                        </div>
                    }
                </div>
            </div>
            {expand && item?.children?.length > 0 &&
                <div className='w-full pl-6'>
                    <ul>
                        {
                            item.children.map((child, idx) => {
                                return (
                                    <li key={idx + 10}>
                                        <LeftPanelItem setTabPanel={setTabPanel} item={child} />
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            }
        </div>
    )
}

export default LeftPanelItem