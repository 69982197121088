import React from 'react';
import Banner from './Banner';
import Categories from './Categories';
import Courses from './Courses';
import Events from './Events';
import Trainee from './trainee';

const Body = () => {
  return (
    <div>
        <Banner/>
        <Categories/>
        <Courses/>
        <Trainee/>
        <Events/>
    </div>
  )
}

export default Body