import React, { useState } from 'react';
import LeftPanelItem from './LeftPanelItem';
import { leftPanelData } from '../../utils/constants';

const LeftPanel = ({setTabPanel}) => {

  return (
    <div className='scroll flex h-screen mr-8 w-72 flex-col items-center overflow-x-hidden bg-[#1c2434]'>
      <div className='w-full flex items-center justify-center py-5'>
        <div className='text-white font-semibold text-2xl'>Admin Panel</div>
      </div>
      <div className='w-full pl-3'>
        <ul>
          {leftPanelData.map((item, idx) => {
            return (
              <li key={idx}>
                <LeftPanelItem setTabPanel={setTabPanel} item={item} />
              </li>
            )
          })
          }
        </ul>
      </div>
    </div>
  )
}

export default LeftPanel