import React from 'react'

const Category = ({ category }) => {
  return (
    <div className='cursor-pointer group flex mx-2 my-2 border h-28 w-72 items-center rounded-md shadow-sm'>
      <div className='w-20 h-20 mx-4 group-hover:bg-[#0e76a8] rounded-full bg-[#EEF2F6] flex justify-center items-center'>
        <img className='group-hover:invert h-1/2 w-1/2' src={category.img} />
      </div>
      <div>
        <div className='group-hover:text-[#0e76a8] font-semibold'>{category.name}</div>
        <div className='text-sm text-[#4f547b]'>{category.desc}</div>
      </div>
    </div>
  )
}

export default Category